import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'

export const Input = styled.input(({ theme, hasError, isTouchedAndValid }) => {
  let borderColor = theme.colors.light.darker.hex

  if (hasError) {
    borderColor = theme.colors.error.base.hex
  }
  if (isTouchedAndValid) {
    borderColor = theme.colors.success.base.hex
  }

  return css`
    padding: ${theme.spacing.xxs} ${theme.spacing.xs};
    border: 1px solid ${borderColor};
    border-radius: ${theme.radius};
    background-color: white;

    &:invalid {
      box-shadow: none;
    }
  `
})

export const Label = styled.label(({ theme, hasError, isTouchedAndValid }) => {
  let labelColor = 'inherit'

  if (hasError) {
    labelColor = theme.colors.error.base.hex
  }
  if (isTouchedAndValid) {
    labelColor = theme.colors.success.base.hex
  }

  return css`
    margin-bottom: ${theme.spacing.sm};
    display: block;
    position: relative;

    .label-text {
      font-size: ${rem(theme.fonts.body.xs.size)};
      margin-left: ${theme.spacing.xs};
      color: ${labelColor};
    }
  `
})

export const Message = styled.div`
  font-size: ${({ theme }) => rem(theme.fonts.body.xs.size)};
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.error.base.hex : 'inherit'};
  position: absolute;
`
