import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './Form.styles'

const Input = props => <Styled.Input {...props} />

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  required: false,
}

Input.propTypes = {
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'string',
    'email',
    'text',
    'hidden',
    'range',
    'checkbox',
    'tel',
    'radio',
    'number',
    'url',
  ]),
}

export default Input
